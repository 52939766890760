import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography, Flex } from '@creditas-ui/react'
import { formatQueryParams } from 'utils/formatQueryParams'
import { navigate } from 'gatsby'
import {
  Wrapper,
  Container,
  Title,
  Description,
  ReferralLink,
  CardsContainer,
  Card,
  CardImageContainer,
  CardImage,
  CardBody,
  CardTitle,
  ButtonContainer,
  Button,
} from './solutions.styles'
import { imagesDesktop, imagesMobile } from './solutions.utils'
import { Colors } from 'utils/colors'

const Solutions = () => {
  const { t } = useTranslation()
  const listCards = t('benefits:solutions:cards', {
    returnObjects: true,
  }).map((card, index) => ({
    ...card,
    imageDesktop: imagesDesktop[index],
    imageMobile: imagesMobile[index],
  }))

  return (
    <Wrapper>
      <Container>
        <Title
          component="h2"
          variant="headingLgLight"
          color="neutral.90"
          dangerouslySetInnerHTML={{
            __html: t('benefits:solutions:title'),
          }}
        />
        <Typography variant="bodyMdRegular" color="neutral.90">
          <Description>{t('benefits:solutions:description')}</Description>
          {t('benefits:solutions:description2')}
          <ReferralLink
            href={formatQueryParams(t('benefits:solutions:referralLink'))}
            target="_blank"
          >
            {t('benefits:solutions:referral')}
          </ReferralLink>
        </Typography>
        <CardsContainer>
          {listCards.map((item, index) => {
            return (
              <Card key={index} onClick={() => navigate(formatQueryParams(item.href))}>
                <CardImageContainer>
                  <CardImage>
                    <source srcSet={item.imageDesktop} media="(min-width: 1024px)" />
                    <img src={item.imageMobile} alt={item.imageAlt} />
                  </CardImage>
                </CardImageContainer>
                <CardBody flexDirection="column">
                  <Flex flexDirection="column">
                    <CardTitle component="h3" color="neutral.90" variant="bodyLgBold">
                      {item.title}
                    </CardTitle>
                    <Typography color="neutral.80" variant="bodyMdRegular">
                      {item.description}
                    </Typography>
                  </Flex>
                  <ButtonContainer alignItems="center" justifyContent="space-between">
                    <Typography variant="bodyMdMedium" color={Colors.Green}>
                      {item.button}
                    </Typography>
                    <Button size="medium" aria-label={item.title} />
                  </ButtonContainer>
                </CardBody>
              </Card>
            )
          })}
        </CardsContainer>
      </Container>
    </Wrapper>
  )
}

export default Solutions
